<template>
  <div>
    <btn-visualizar-vencidos
      @click="openFidelizacoesVencidas"
      :qtdVencidos="fidelizacoesVencidas.length"
    ></btn-visualizar-vencidos>


    <b-modal id="modal-fidelizacoes-vencidas" hide-footer>
      <template #modal-title>
        <div><b-icon icon="credit-card"></b-icon> Fidelizações vencidas</div>
      </template>

      <list-fidelizacoes
        :fidelizacoes="fidelizacoesVencidas"
        @renovar="onRenovar"
        :tiposFildel="tiposFildel"
      ></list-fidelizacoes>
    </b-modal>
  </div>
</template>

<script>
import FidelizacoesLib from "../../../libs/FidelizacoesLib";
export default {
  components: {
    btnVisualizarVencidos: () => import("./btnVisualizarVencidos.vue"),
    listFidelizacoes: () => import("./listFidelizacoes.vue")
  },
  props: {
    fidelizacoes: Array,
    entidade_id: [String, Number]
  },
  data() {
    return {
      fidelizacoesVencidas: [],
      tiposFildel:[]
    };
  },
  methods: {
    openFidelizacoesVencidas() {
      this.$bvModal.show("modal-fidelizacoes-vencidas");
    },
    closeFidelizacoesVencidas() {
      this.$bvModal.hide("modal-fidelizacoes-vencidas");
    },
    onRenovar(fidelizacao) {
      this.$emit("renovar", fidelizacao);
      this.closeFidelizacoesVencidas();
    },
    async carregarTipos() {
      this.tiposFildel = await FidelizacoesLib.get();
      //console.log("tpsfil", this.tiposFildel);
    }
  },
  mounted() {
    if (Array.isArray(this.fidelizacoes) && this.fidelizacoes.length > 0) {
      this.fidelizacoesVencidas = Object.assign([], this.fidelizacoes);
    } else if (this.entidade_id) {
      // pega as fidelidades vencidas por entidade...
    }
    //console.log("lista filds", this.fidelizacoes);
    this.carregarTipos();
  },
  created() {}
};
</script>

<style></style>
